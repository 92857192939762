<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8"><el-input placeholder="Search by code or name" prefix-icon="el-icon-search"
          v-model="filters.searchString"> </el-input>
      </el-col>
      <el-col :span="3.5" v-show="!selected.items.length">
        <el-tooltip class="item" effect="dark" content="Make selected products active" placement="bottom">
          <el-button type="primary" icon="el-icon-circle-plus" @click="onNewProduct">New Product</el-button>
        </el-tooltip>
      </el-col>
      <el-col :span="3.5" v-show="selected.items.length == 1">
        <el-tooltip class="item" effect="dark" content="Make selected products active" placement="bottom">
          <el-button type="primary" icon="el-icon-edit" @click="onNewProduct">Edit Product</el-button>
        </el-tooltip>
      </el-col>
      <el-col :span="3.5">
        <el-tooltip class="item" effect="dark" content="Make selected products active" placement="bottom">
          <el-button type="success" icon="el-icon-circle-check" @click="onConfirm()">Make Active</el-button>
        </el-tooltip>
      </el-col>
      <el-col :span="3.5">
        <el-tooltip class="item" effect="dark" content="Make selected products inactive" placement="bottom">
          <el-button type="danger" icon="el-icon-circle-close" @click="onConfirm()">Make Inactive</el-button>
        </el-tooltip>
      </el-col>
      <el-col :span="3.5">
        <p class="mt-2">{{ itemsShowing }}</p>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-table ref="elTable" :data="filteredData" :row-key="getRowKey" @selection-change="handleSelectionChange"
          style="width: 100%" border size="small" :cell-style="{ padding: '0', height: '20px' }"
          :header-cell-style="{ background: '#f1f1f5', color: 'black', textAlign: 'center' }">
          <el-table-column type="selection" width="35" :reserve-selection="true" />
          <el-table-column width="100">
            <template slot-scope="scope">
              <el-image :src="getImage(scope.row.imageLink)" style="width: 80px; height: 80px" class="my-2 rounded-lg"
                alt="Loading" lazy fit="fit" @click="previewURL(scope.row.imageLink)" />
            </template>
          </el-table-column>
          <el-table-column property="code" label="Code" align="center" />
          <el-table-column property="name" label="Name" align="center" />
          <el-table-column property="category.name" label="Category" align="center" :filters="filters.categories"
            :filter-method="filterHandler" />
          <el-table-column property="subCategory.name" label="Subcategory" align="center" :filters="filters.subcategories"
            :filter-method="filterHandler" />
          <el-table-column property="supplierCode" label="Supplier" align="center" :filters="filters.suppliers"
            :filter-method="filterHandler" />
          <el-table-column property="price" label="Price" align="center" />
          <el-table-column label="Status" width="100" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isActive" type="success" size="mini">Active</el-tag>
              <el-tag v-else-if="!scope.row.isActive" type="danger" size="mini">Inactive</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- Modals -->
    <!-- <NewProductForm :dialogVisible.sync="dialogs.newProduct" :data="selected.items[0]" /> -->
    <!-- End -->
  </div>
</template>

<script>
import { getImage } from "../../common/utils";
import { imagePreviewHelper } from "../../config/constants";
import { getProducts } from "./lib";

export default {
  name: "Products",
  computed: {
    filteredData() {
      let searchString = this.filters.searchString;
      const filteredProducts = this.products.filter(function (item) {
        return (
          item.name.toUpperCase().indexOf(searchString.toUpperCase()) >= 0 ||
          item.code.toUpperCase().indexOf(searchString.toUpperCase()) >= 0
        );
      });

      this.$nextTick(() => {
        this.triggerScrollEvent();
      });

      return filteredProducts;
    },
    itemsShowing() {
      if (!this.isMounted) return;
      const filteredItemsLength = this.$refs.elTable.tableData.length;
      const totalItemsLength = this.products.length;
      return `Showing ${filteredItemsLength} / ${totalItemsLength} Items`;
    },
  },
  data() {
    return {
      products: [],
      selected: {
        items: [],
      },
      filters: {
        categories: [],
        suppliers: [],
        subcategories: [],
        searchString: "",
      },
      dialogs: {
        newProduct: false,
      },
      isMounted: false,
    };
  },
  methods: {
    getImage: getImage,
    previewURL(imageLink) {
      this.$viewerApi(imagePreviewHelper(imageLink));
    },
    triggerScrollEvent() {
      const scrollEvent = new Event('scroll');
      window.dispatchEvent(scrollEvent);
    },
    async onInit() {
      this.products = await getProducts();
      this.setFilters();
    },
    setFilters() {
      this.filters.categories = [...new Set(this.products.map((item) => item.category.name))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.suppliers = [...new Set(this.products.map((item) => item.supplierCode))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.subcategories = [...new Set(this.products.map((item) => item.subCategory?.name))]
        .map((item) => ({
          text: item,
          value: item,
        }))
        .sort((a, b) => a.text?.localeCompare(b.text) ?? 0);
    },
    handleSelectionChange(item) {
      this.selected.items = item;
    },
    filterHandler(value, row, column) {
      return this.resolve(column["property"], row) === value;
    },
    resolve(path, obj) {
      return path.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    getRowKey(row) {
      return row.id;
    },
    onNewProduct() {
      this.$router.push({ name: "NewProduct", params: { product: this.selected.items[0] } });
    },
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.onInit();
    this.isMounted = true;
    await this.$store.dispatch("setLoading", false);
  },
};
</script>
<style></style>
